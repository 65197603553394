// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;

  --ion-color-primary: #B00020;
  --ion-color-primary-rgb: 54, 54, 54;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #303030;
  --ion-color-primary-tint: #4a4a4a;

  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  --ion-color-tertiary: #d9534f;
  --ion-color-tertiary-rgb: 217, 83, 79;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #bf4946;
  --ion-color-tertiary-tint: #dd6461;

  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #363636;
  --ion-color-dark-rgb: 54, 54, 54;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #303030;
  --ion-color-dark-tint: #4a4a4a;

  --ion-color-medium: #787878;
  --ion-color-medium-rgb: 120, 120, 120;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6a6a6a;
  --ion-color-medium-tint: #868686;

  --ion-color-light: #c3c3c3;
  --ion-color-light-rgb: 195, 195, 195;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #acacac;
  --ion-color-light-tint: #c9c9c9;
}

:root {
  --ion-color-twitter: #1DA1F2;
  --ion-color-twitter-rgb: 29, 161, 242;
  --ion-color-twitter-contrast: #FFFFFF;
  --ion-color-twitter-contrast-rgb: 0, 0, 0;
  --ion-color-twitter-shade: #1a8ed5;
  --ion-color-twitter-tint: #34aaf3;
}

.ion-color-twitter {
  --ion-color-base: var(--ion-color-twitter);
  --ion-color-base-rgb: var(--ion-color-twitter-rgb);
  --ion-color-contrast: var(--ion-color-twitter-contrast);
  --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
  --ion-color-shade: var(--ion-color-twitter-shade);
  --ion-color-tint: var(--ion-color-twitter-tint);
}

:root {
  --ion-color-facebook: #4267B2;
  --ion-color-facebook-rgb: 66, 103, 178;
  --ion-color-facebook-contrast: #ffffff;
  --ion-color-facebook-contrast-rgb: 255, 255, 255;
  --ion-color-facebook-shade: #3a5b9d;
  --ion-color-facebook-tint: #5576ba;
}

.ion-color-facebook {
  --ion-color-base: var(--ion-color-facebook);
  --ion-color-base-rgb: var(--ion-color-facebook-rgb);
  --ion-color-contrast: var(--ion-color-facebook-contrast);
  --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
  --ion-color-shade: var(--ion-color-facebook-shade);
  --ion-color-tint: var(--ion-color-facebook-tint);
}

:root {
  --ion-color-google: #69bb7b;
  --ion-color-google-rgb: 105, 187, 123;
  --ion-color-google-contrast: #ffffff;
  --ion-color-google-contrast-rgb: 255, 255, 255;
  --ion-color-google-shade: #5ca56c;
  --ion-color-google-tint: #78c288;
}

.ion-color-google {
  --ion-color-base: var(--ion-color-google);
  --ion-color-base-rgb: var(--ion-color-google-rgb);
  --ion-color-contrast: var(--ion-color-google-contrast);
  --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
  --ion-color-shade: var(--ion-color-google-shade);
  --ion-color-tint: var(--ion-color-google-tint);
}

:root {
  --ion-color-list-highlighted: #f7dbdb;
  --ion-color-list-highlighted-rgb: 247, 219, 219;
  --ion-color-list-highlighted-contrast: #000000;
  --ion-color-list-highlighted-contrast-rgb: 0, 0, 0;
  --ion-color-list-highlighted-shade: #d9c1c1;
  --ion-color-list-highlighted-tint: #f8dfdf;
}

.ion-color-list-highlighted {
  --ion-color-base: var(--ion-color-list-highlighted);
  --ion-color-base-rgb: var(--ion-color-list-highlighted-rgb);
  --ion-color-contrast: var(--ion-color-list-highlighted-contrast);
  --ion-color-contrast-rgb: var(--ion-color-list-highlighted-contrast-rgb);
  --ion-color-shade: var(--ion-color-list-highlighted-shade);
  --ion-color-tint: var(--ion-color-list-highlighted-tint);
}
