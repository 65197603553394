/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {
  font-family: 'urigul';
  src: url('./assets/fonts/kpcholin_subset.woff2') format('woff2'), url('./assets/fonts/kpcholin_subset.woff') format('woff');
  font-weight: 400;
}

// .urigul クラスを設定した箇所は、専用の朝鮮語フォントを使用する
// カラオケ目録にならい、太字にはせず大きな文字にする
.urigul {
  font-family: 'urigul', sans-serif !important;
  letter-spacing: -0.1em;

  strong {
    font-weight: normal;
    font-size: larger;
    letter-spacing: -0.1em;
  }
}

ion-note {
  // Android で ion-note が小さすぎる問題に対応
  font-size: 16px;
}
